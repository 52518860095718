/* @tailwind base;
@tailwind components;
@tailwind utilities; */
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
html {
  font-size: 16px;
}

body,
html {
  font-family: 'Noto Sans KR', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.ant-message-notice {
  padding: 10px 30px;
  text-align: left;
  text-align: center;
}
.ant-message-notice .ant-message-notice-content {
  max-width: 1476px;
  width: 100%;
}

.ant-message-notice .ant-message-custom-content {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.ant-message-notice .ant-message-custom-content svg {
  margin-right: 15px;
}

.ant-checkbox:not(.ant-checkbox-indeterminate) .ant-checkbox-inner:after {
  left: 6px;
}
